var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('v-card',{staticClass:"mt-2"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getFirewallRules,"item-key":"uid","sort-by":['started_at'],"sort-desc":[true],"items-per-page":10,"footer-props":{'items-per-page-options': [10, 25, 50, 100]},"server-items-length":_vm.getNumberFirewallRules,"options":_vm.pagination,"disable-sort":true,"data-test":"firewallRuleList-dataTable"},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
var item = ref.item;
return [(item.active)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" check_circle ")]):_c('v-icon',{attrs:{"bottom":""}},[_vm._v(" check_circle ")])]}},{key:"item.priority",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.priority)+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.action)+" ")]}},{key:"item.source_ip",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.source_ip)+" ")]}},{key:"item.username",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.username)+" ")]}},{key:"item.hostname",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.hostname)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{ref:'menu'+_vm.getFirewallRules.indexOf(item),attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g({attrs:{"color":"transparent"}},on),[_c('v-icon',_vm._g(_vm._b({staticClass:"icons",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-horizontal ")])],1)]}}],null,true)},[_c('v-card',[_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();_vm.showFirewallRuleEdit(_vm.getFirewallRules.indexOf(item))}}},[_c('FirewallRuleEdit',{attrs:{"firewall-rule":item,"create-rule":false,"show":_vm.firewallRuleEditShow[_vm.getFirewallRules.indexOf(item)],"data-test":"firewallRuleEdit-component"},on:{"update:show":function($event){_vm.$set(_vm.firewallRuleEditShow, _vm.getFirewallRules.indexOf(item), $event)},"update":_vm.refresh}})],1),_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();_vm.showFirewallRuleDelete(_vm.getFirewallRules.indexOf(item))}}},[_c('FirewallRuleDelete',{attrs:{"id":item.id,"show":_vm.firewallRuleDeleteShow[_vm.getFirewallRules.indexOf(item)],"data-test":"firewallRuleDelete-component"},on:{"update:show":function($event){_vm.$set(_vm.firewallRuleDeleteShow, _vm.getFirewallRules.indexOf(item), $event)},"update":_vm.refresh}})],1)],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }